<template>
  <div id="app">
  <!-- <div class="appOut">
    <home-head />
    <div class="" :class="{ 'out': $route.meta.isSidebar }" v-if="$route.meta.isSidebar">
      <SidebarIndex v-if="$route.meta.isSidebar" />
      <div class=""  :class="{ 'sidebarOut': $route.meta.isSidebar }">
        <router-view />
      </div>
    </div>
    <router-view  v-else/>
    <fix-label />

    <Footer v-if="$route.meta.isFooter" />
  </div> -->
  <div class="main">
    <leftHead ></leftHead>
    <div class="rightApp" :style="{ width: isExpand ? '1200px' : '1380px',}">
      <rightHead ></rightHead>
      <div class="subjectDemo" id="subjectDemo" :style="{background: $route.path == '/ebpayActivity' ? '#1A2C38':''}">
        <router-view />
        <Footer v-if="$route.meta.isFooter" />
      </div>
      
    </div>
  </div>
  <login v-if="popShow.loginShow" />
     <register  v-if="popShow.registerShow"/>
   <recover  v-if="popShow.recoverShow" />
   <!-- @breakRegister="breakRegister" -->
    <terms   v-if="popShow.termsShow" />
    <deposit v-if="popShow.depositShow"  />
    <withdrawals v-if="popShow.withdrawalShow"  />
    <Convert v-if="popShow.convertShow"  />
    <DepositContent v-if="popShow.depositContentShow"  />
    
  </div>
</template>

<script>

import Login from "@/components/login/login.vue";
import Recover from "@/components/login/recover.vue";
import Register from "@/components/login/register.vue";
import Terms from "@/components/login/terms.vue";
import Deposit from "@/components/deposit-with/deposit.vue";
import DepositContent from "@/components/deposit-with/depositContent.vue";
import Withdrawals from "@/components/deposit-with/withdrawals.vue";
import Convert from "@/components/deposit-with/transfers.vue";
// import FixLabel from './components/fixLabel.vue';
import Footer from './components/Footer.vue';
// import homeHead from './components/homeHead.vue';

// import SidebarIndex from './components/Sidebar.vue';
import rightHead from './components/newHead/rightHead.vue';
import leftHead from './components/newHead/leftHead.vue';
import { mapState } from 'vuex';
export default {
  components: {
    //  homeHead, SidebarIndex, 
    Footer, 
    // FixLabel,
    leftHead,
    rightHead,
    Login,
    Register,
    Recover,
    Terms,
    Deposit,
    Withdrawals,
    Convert,
    DepositContent
   },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      isExpand: state => state.isExpand,
      popShow: state => state.popShow,

    })
  },
  methods: {
    
  },
  mounted() {
   
  },
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.appOut{
  width: 1440px;
  margin: 0 auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background: #101B1F;
  min-height: 100vh;
  transition: all 0.5s;
  
}
.main{
  display: flex;
  align-items: flex-start;
  width: 1440px;
  justify-content: space-between;
  margin: 0 auto;
  .rightApp{
    transition: all 0.2s ease;
    
    .subjectDemo{
      height: calc( 100dvh);
      padding-bottom: 60px;
      overflow-y: auto;
      
    }
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.out {
  display: flex;
  justify-content: flex-start;

  .sidebarOut {
    width: 72.91666%;
    // margin-left: 310px;
    margin-left: 10px;
    margin-top: 40px;
  }
}

//时间筛选下拉
.popDate {
  // background: #1E303A !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.09) !important;
  color: #fff !important;

  border-radius: 16px !important;
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0px 10px 20px 0px rgba(16, 27, 31, 0.40);
  backdrop-filter: blur(30px);

  .el-picker-panel__icon-btn {
    color: #fff;
  }

  .el-date-picker__header-label {
    color: #fff;
  }

  .el-date-table th {
    color: rgba(255, 255, 255, 0.4);
  }

  .popper__arrow {
    border-top-color: #000 !important;
    border-bottom-color: #000 !important;
    display: none !important;
  }

  .popper__arrow::after {
    display: none !important;
    border-top-color: #000 !important;
  }

  .in-range div span {
    color: #0385FF;
  }

  .el-date-table td.end-date span,
  .el-date-table td.start-date span {
    border-radius: 17px;
    background-color: rgba(3, 133, 255, 0.05);
  }

  .el-date-table td.in-range div,
  .el-date-table td.in-range div:hover,
  .el-date-table.is-week-mode .el-date-table__row.current div,
  .el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: #1E303A;
  }
}

.elPopper {
  border: 0 !important;
  background-color: #1E303A !important;

  .el-select-dropdown__item {
    color: #fff !important;
  }

  .el-select-dropdown__item {
    height: 48px;
    line-height: 48px;
  }

  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: rgba(255, 255, 255, 0.09);
  }


  .popper__arrow {
    display: none !important;
  }

  .el-date-table td.in-range div,
  .el-date-table td.in-range div:hover,
  .el-date-table.is-week-mode .el-date-table__row.current div,
  .el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: #1E303A;
  }

  //联级
  .el-cascader-menu {
    border: 0;
  }

  .el-cascader-node {
    color: #FFF;
  }

  .el-cascader-node:not(.is-disabled):focus,
  .el-cascader-node:not(.is-disabled):hover {
    background: rgba(255, 255, 255, 0.04);
  }

  .el-select-dropdown__wrap {

    // margin-bottom: -16px !important;
  }
}

body {
  padding-right: 0px !important;
  overflow: hidden;
}

body::-webkit-scrollbar {
  border-radius: 2px;
  background: #101B1F;
  width: 4px;
  height: auto;
  flex-shrink: 0;
}

body::-webkit-scrollbar-thumb {
  width: 4px;
  height: auto;
  flex-shrink: 0;
  border-radius: 2px;
  background: #283E4C;
}

.subjectDemo::-webkit-scrollbar {
  border-radius: 2px;
  background: #101B1F;
  width: 4px;
  height: auto;
  flex-shrink: 0;
}

.subjectDemo::-webkit-scrollbar-thumb {
  width: 4px;
  height: auto;
  flex-shrink: 0;
  border-radius: 2px;
  background: #283E4C;
}
.el-message {
  z-index: 5000 !important; /* 根据实际情况设置适当的值 */
}

</style>
