<template>
<div>
  <el-dialog
    class="withDepostDialog"
    :visible="depositContentShow"
    @close="closeDialog('all')"
    :show-close="false"
  >
    <div class="diaTitle">
      <div class="diaTit">USDT充值</div>
      <img src="@/assets/postWith/titleColse.svg" @click="closeDialog('all')" />
    </div>
    <div class="contents" v-if="data">
      <div class="wbxqblm">
        <div class="title">
          <div class="titleLeft">
            <div class="blueVertical"></div>
            选择主网
            <div class="tips">
              <img
                src="@/assets/postWith/wran.svg"
              />请选择与<span>提币平台一致的网络</span>，否则资金会丢失
            </div>
          </div>
        </div>
        <div class="trcAndErc">
          <div class="trcCont" @click="trcOrErc = 0">
            <img v-show="trcOrErc == 1" src="@/assets/info/round.svg" />
            <img v-show="trcOrErc == 0" src="@/assets/info/round-dg.svg" />
            TRC20
          </div>
          <div class="trcCont" @click="trcOrErc = 1">
            <img v-show="trcOrErc == 0" src="@/assets/info/round.svg" />
            <img v-show="trcOrErc == 1" src="@/assets/info/round-dg.svg" />
            ERC20
          </div>
        </div>
      </div>
      <div class="wbxqblm">
        <div class="title">
          <div class="titleLeft">
            <div class="blueVertical"></div>
            充值地址
            <div class="tips">
              <img src="@/assets/postWith/wran.svg" />地址有效期<span>{{
                extend.expireTime
              }}</span
              >，超时则无法到账或退款
            </div>
          </div>
        </div>
        <div class="ewm" v-if="extend">
          <img :src="trcOrErc == 0 ? trc20.qrcode : erc20.qrcode" />
          <!-- <div
          class="downImg"
          @click="downloadImage(trcOrErc == 0 ? trc20.qrcode : erc20.qrcode)"
        >
          保存图片
        </div> -->
        </div>
        <div class="address">
          <div class="addLink">
            {{ trcOrErc == 0 ? trc20.address : erc20.address }}
          </div>
          <div
            class="copyDemo"
            @click="copyBtn(trcOrErc == 0 ? trc20.address : erc20.address)"
          >
            <img src="@/assets/postWith/copy.svg" />
          </div>
        </div>
      </div>

      <div class="wbxqblm">
        <div class="title">
          <div class="titleLeft">
            <div class="blueVertical"></div>
            充值金额
            <div class="messageGreen">
              <img
                src="@/assets/postWith/greenLamp.svg"
              />温馨提示：请按照选定金额支付，以便为您快速核实！
            </div>
          </div>
        </div>
        <div class="postMoney">
          <div class="money">
            {{ Math.floor(extend.amount) + "." }}
            <span>{{ getdecimal(extend.amount) }}</span>
          </div>
          <div class="moneyRight">
            <div class="usdTips">USDT</div>
            <!-- <div class="shu"></div> -->
            <div class="copyDemo" @click="copyBtn(extend.amount)">
              <img src="@/assets/postWith/copy.svg" />
            </div>
          </div>
        </div>
        <div class="tips lineTips">
          <img
            src="@/assets/postWith/wran.svg"
          />请务必按<span>此金额</span>转账，以免<span>延迟或丢失</span>
        </div>
        <!-- <div class="average">
        <div>平均到账时间：5-10分钟</div>
        <div>当前汇率：{{ extend.rate }}</div>
      </div> -->
        <div class="hint">
          <div>1.此地址只可接受USDT（TRC20）充值；</div>
          <div>2.最低充值需大于10USDT，否则将无法入账或退款；</div>
          <div>
            3.请留意您发起提币的平台是否有收取手续费，这将影响您的实际到帐金额；
          </div>
          <div>
            4.复制并黏贴地址后，请再次核实您黏贴上的地址与收银台上显示的地址完全相符，以避免资产损失；
          </div>
          <div>5.地址充值后可能失效，切勿自行储存扫码地址进行充值。</div>
        </div>
      </div>
  <div class="ckydwt">
        <div class="encounter">
          存款遇到问题？联系<img src="@/assets/login/service.svg"   @click="getService()" /><span   @click="getService()"
            >在线客服</span
          >
        </div>
        <div class="btnLIne">
            <van-button class="cxczBtn" :disabled="okLoading" @click="submit"
          >撤销充值</van-button
        >
        <van-button
         
          class="btn"
          :loading="okLoading"
          @click="okPost"
          >完成充值</van-button
        >
        </div>
        
      </div>
      <!-- <div class="dibu">
        <van-button class="btn" :disabled="okLoading" @click="submit"
          >撤销充值</van-button
        >
        <van-button class="btn" :loading="okLoading" @click="okPost"
          >完成充值</van-button
        >
      </div> -->

      <!-- class="popOuts" -->
      
    </div>
  </el-dialog>
  <el-dialog
        class="withDepostDialog cxDiaLog"
        :visible="showCancel"
        @close="showCancel = false"
        :show-close="false"
      >
      <div class="diaTitle">
      <div class="diaTit">撤销申请</div>
      <img src="@/assets/postWith/titleColse.svg" @click="showCancel = false" />
    </div>
        <div class="popTips">
          <div class="titlePop">是否取消本次申请？</div>
          <div class="popTxt">
            如果您已完成转账，请勿取消，我们将尽快为您处理！
          </div>
          <div class="titleTwo">请选择取消原因</div>
          <!-- <div class="tishi">请选择取消原因</div> -->
          <van-button
            class="moren"
            :class="{ selectCancel: cancelTab == 0 }"
            @click="cancelTab = 0"
            >银行卡异常/限额</van-button
          >
          <van-button
            class="moren"
            :class="{ selectCancel: cancelTab == 1 }"
            @click="cancelTab = 1"
            >找不到开户行</van-button
          >
          <van-button
            class="moren"
            :class="{ selectCancel: cancelTab == 2 }"
            @click="cancelTab = 2"
            >不想存款了</van-button
          >
          <van-field
            v-model="message"
            autosize
            class="message"
            type="textarea"
            placeholder="如遇到其他问题请在这里说明"
          />
          <div class="btnLine">
            <van-button class="wzxx" @click="showCancel = false"
              >我再想想</van-button
            >
            <van-button
              class="qrqx"
              :loading="subLoading"
              :disabled="subLoading"
              @click="cancelBtn"
              >确认取消</van-button
            >
          </div>
        </div>
      </el-dialog>
      </div>
</template>

<script>
import { depositFeedback, paymentConfirm } from "@/request/api";
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      trcOrErc: 0,
      data: {},
      trc20: {},
      erc20: {},
      extend: {},
      showCancel: false,
      cancelTab: 0,
      message: "",
      subLoading: false,
      okLoading: false,
    };
  },
  computed: {
    ...mapState({
      depositContentShow: (state) => state.popShow.depositContentShow,
    }),
  },
  mounted() {
    console.log("usd", this.$store.state.usdData);
    if (this.$route.query.usdData) {
      this.data = JSON.parse(this.$route.query.usdData);
      this.trc20 = JSON.parse(this.$route.query.usdData).extend.trc20;
      this.erc20 = JSON.parse(this.$route.query.usdData).extend.erc20;
      this.extend = JSON.parse(this.$route.query.usdData).extend;
    } else if (this.$store.state.usdData) {
      this.data = this.$store.state.usdData;
      this.trc20 = this.$store.state.usdData.extend.trc20;
      this.erc20 = this.$store.state.usdData.extend.erc20;
      this.extend = this.$store.state.usdData.extend;
    } else {
      this.$notify({
        message: "数据错误，请重新提交",
        duration: 2000,
        background: "#FF4337",
      });
    }
  },
  methods: {
    ...mapActions(["setActivePop"]),
    closeDialog(fieldName) {
      this.setActivePop(fieldName);
    },
    getService() {
      this.$store.commit("goService");
    },
    cancelBtn() {
      var actTxt =
        this.cancelTab == 0
          ? "银行卡异常/限额"
          : this.cancelTab == 1
          ? "找不到开户行"
          : "不想存款了";

      this.subLoading = true;
      var opt = {
        orderNo: this.data.orderNo,
        text: `${actTxt}${this.message ? "," : ""}${this.message}`,
      };
      depositFeedback(opt).then((res) => {
        if (res.code == 0) {
         

          this.$message.success("取消成功")
          this.showCancel = false;
          this.closeDialog("all");
          this.$router.go(0)
          // this.$router.push("/deposit");
        }
        this.subLoading = false;
      });
    },
    submit() {
      this.showCancel = true;
    },
    okPost() {
      this.okLoading = true;
      var opt = {
        orderNo: this.data.orderNo,
        protocol: this.trcOrErc == 0 ? "TRC" : "ERC",
      };
      paymentConfirm(opt).then((res) => {
        if (res.code == 0) {
          this.$message.success("已提交订单，我们尽快为您到账…")
          this.closeDialog("all");
          window.location.reload();
        }
        this.okLoading = false;
      });
    },
    getdecimal(num) {
      if (!num) {
        return;
      }
      const numberAsString = num.toString();
      const decimalPart = numberAsString.includes(".")
        ? numberAsString.split(".")[1]
        : "00";
      let formattedDecimalPart = decimalPart;
      if (formattedDecimalPart.length === 1) {
        formattedDecimalPart += "0";
      }
      return formattedDecimalPart;
    },
    copyBtn(value) {
      const nameToCopy = value;
      const textField = document.createElement("textarea");
      textField.innerText = nameToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      this.$message.success("复制成功")
    },
    downloadImage(value) {
      const base64Data = value; // 替换为你的 base64 图片数据
      const blob = this.base64ToBlob(base64Data);
      const imageUrl = window.URL.createObjectURL(blob); // 图片URL
      const xhr = new XMLHttpRequest();
      xhr.open("GET", imageUrl, true);
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          const url = window.URL.createObjectURL(xhr.response);
          const a = document.createElement("a");
          a.href = url;
          a.download = "image.jpg"; // 下载的文件名
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      };
      xhr.send();
    },
    base64ToBlob(base64Data) {
      const byteString = atob(base64Data.split(",")[1]);
      const mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },
  },
};
</script>

<style lang="less" scoped>
.withDepostDialog {
  /deep/.el-dialog {
    display: flex;
    width: 700px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: 10px;
    background: radial-gradient(
      133.65% 137.5% at -19.87% -5.42%,
      #02568e 6%,
      #13293b 61.18%,
      #152b3f 100%
    );

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 100%;
      padding: 20px;
    }
  }

  .diaTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    .diaTit {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
    }

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}
.contents {
  background-repeat: no-repeat;
  background-size: cover;
  // background: radial-gradient(
  //   98.3% 154.62% at 131.6% -1.79%,
  //   #234b6b 8.22%,
  //   #18222a 61.18%,
  //   #18222a 100%
  // );
  // padding-bottom: 60px;
  // position: relative;

  .wbxqblm {
    width: 100%;
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: 10px;
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    margin-bottom: 14px;
  }

  .title {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: space-between;
    color: #fff;
    font-family: "PingFang SC";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;

    .titleLeft {
      display: flex;
      align-items: center;
      white-space: nowrap;
      .blueVertical {
        width: 4px;
        height: 32px;
        border-radius: 0px 8px 8px 0px;
        background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
        margin-right: 10px;
      }

      .messageGreen {
        display: flex;
        padding: 5px 10px;
        align-items: center;
        gap: 5px;
        border-radius: 10px;
        background: rgba(44, 209, 88, 0.09);
        color: #2cd158;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-left: 10px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .titRight {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: right;
      font-family: DIN;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;

      img {
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }
    }
  }

  .trcAndErc {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin: 0px auto 0 auto;

    .trcCont {
      width: 165px;
      height: 40px;
      display: flex;
      padding: 10px;
      align-items: center;
      gap: 5px;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.04);
      background: rgba(255, 255, 255, 0.04);
      color: #fff;
      font-family: "SF Pro Display";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .tips {
    display: flex;
    align-items: center;
    text-align: left;
    width: 89.33333%;
    // margin: 4px auto 0 auto;
    margin-left: 21px;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.65);
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    span {
      color: #ffa103;
    }

    img {
      width: 16px;
      height: 16px;
      margin-top: 2px;
    }
  }
  .lineTips{
    width: 100%!important;
    margin-left:0;
    margin-top: 20px;
  }
  .ewm {
    margin: 0px auto 0 auto;

    img {
      width: 160px;
      height: 160px;
      flex-shrink: 0;
    }

    .downImg {
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;
    }
  }

  .address {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.04);
    width: 100%;
    margin: 20px auto 0 auto;
    justify-content: space-between;

    .addLink {
      color: #fff;
      font-family: "SF Pro Display";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      /* 18px */
      width: 90%;
      word-wrap: break-word;
      text-align: left;
    }

    .copyDemo {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 17px;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);

      img {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }

  .postMoney {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.04);
    width: 100%;
    height: 60px;
    margin: 0px auto 0 auto;
    justify-content: space-between;

    .money {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      /* 36px */
      text-align: left;

      span {
        color: rgba(255, 255, 255, 0.65);
      }
    }

    .moneyRight {
      display: flex;
      align-items: center;

      .usdTips {
        color: rgba(255, 255, 255, 0.65);
        font-family: "SF Pro Display";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        margin-right: 10px;
      }

      .shu {
        width: 1px;
        height: 12px;
        background: rgba(255, 255, 255, 0.85);
        margin: 0 10px;
      }
    }

    .copyDemo {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 17px;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);

      img {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }

  .average {
    color: rgba(255, 255, 255, 0.65);
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px auto 0 auto;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hint {
    width: 100%;
    margin: 10px auto 0 auto;
    padding: 12px;

    border-radius: 8px;
    background: rgba(3, 133, 255, 0.09);
    align-self: stretch;
    color: rgba(3, 133, 255, 1);
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 158%;
    text-align: left;
  }

  .dibu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    height: 58px;
    flex-shrink: 0;
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(2px);
    .cxcz {
      background: transparent !important;
      border: 1px solid #0385ff !important;
    }
    .btn {
      white-space: nowrap;
      display: inline-flex;
      padding: 10px 80px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 19.5px */
      border: 0;
      width: 44%;
    }
  }
}

.popOuts {
  background: transparent;
  width: 100%;
  
}
.cxDiaLog{
  
}
.popTips {
    width: 100%;
    display: inline-flex;
    // padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    // background: radial-gradient(
    //   98.3% 154.62% at 131.6% -1.79%,
    //   #234b6b 8.22%,
    //   #18222a 61.18%,
    //   #18222a 100%
    // );
    .titlePop {
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    .popTxt {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      text-align: left;
    }
    .titleTwo {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      text-align: left;
      width: 100%;
    }
    .tishi {
      display: flex;
      width: 100%;
      height: 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.04);
      background: rgba(255, 255, 255, 0.04);
      color: rgba(255, 255, 255, 0.85);
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .moren {
      display: flex;
      width: 100%;
      height: 42px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: rgba(255, 255, 255, 0.85);
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.04);
      background: rgba(255, 255, 255, 0.04);
    }
    .selectCancel {
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.04) !important;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
    }
    .message {
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.04);
      background: rgba(255, 255, 255, 0.04);
      display: flex;
      width: 100%;
      height: 80px;
      padding: 5px 10px;
      align-items: flex-start;
      gap: 10px;
      color: #fff;
      /deep/.van-field__control {
        color: #fff;
      }
      /deep/.van-cell__value::after {
        content: "";
      }
      /deep/.van-field__body::after {
        content: "";
      }
    }
    .btnLine {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .wzxx {
        display: flex;
        height: 36px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border: 0;
        background: transparent;
        color: #fff;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        white-space: nowrap;
      }
      .qrqx {
        display: flex;
        height: 36px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border: 0;
        background: transparent;
        color: #0385ff;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }
/deep/.van-cell::after {
  content: "" !important;
  border-bottom: 0 !important;
}

.ckydwt {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .encounter {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;

      img {
        margin: 0 5px 0 10px;
        cursor: pointer;
      }

      span {
        cursor: pointer;
                user-select: none;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(180deg, #fff -54.54%, #c4deff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .btnLIne{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  .cxczBtn{
    min-width: 78px;
  height: 20px;
  text-align: center;
  color: rgba(3, 133, 255, 1);
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  border:0;
  background: transparent;
  white-space: nowrap;
  }
    .btn {
      display: flex;
      width: 280px;
      height: 46px;
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 0;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 24px */
    }
  }
</style>
