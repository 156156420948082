import axios from "axios";
import Vue from 'vue'; // 引入 Vue
// import { Notify } from "vant";
//import qs from 'qs'
import router from "@/router";
 import FingerprintJS from '@fingerprintjs/fingerprintjs'
 
 var myIp = '';
// axios 配置
const service = axios.create({
  baseURL: "", // api的base_url  https://www.pandapro.me/
  timeout: 50000, // 请求超时时间
  withCredentials: true, // 表示跨域请求时是否需要使用凭证
  responseType: "json", // 表示服务器响应的数据类型
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Accept-Device":"pc",
    "Accept-Device-Version":"1.0.0"
  },
  // transformRequest: [function (data) {
  //   // 对 data 进行任意转换处理
  //   return qs.stringify(data)
  // }],
  // transformResponse: [function (data) {
  //   // 对 data 进行任意转换处理
  //   return data
  // }],
  // validateStatus: function (status) {
  //   // 状态码在大于或等于 200 且小于 300 时才会 resolve
  //   return status >= 200 && status < 300
  // }
});

// 初始化时获取UUID
service.get('/client/home/ip').then(response => {
  if (response.data && response.data.code === 0) {
    myIp = response.data.data;
  }
}).catch(error => {
  console.error('获取IP失败:', error);
});

// http 请求拦截器
service.interceptors.request.use(
  async (config) => {
    const fp = await FingerprintJS.load();
    // const result = await fp.get();
    const result = await fp.get(components => {
      components.push({
        key: 'ip',
        value: myIp// 你获取到的动态 IP 地址
      });
    });
    const visitorId = result.visitorId;
    config.headers['Accept-uuid'] = visitorId;


    // config.headers['Access-Control-Allow-Credentials'] = true
    var token = localStorage.getItem("token");
    if (token) {
      //config.headers['Authorization'] = 'Bearer ' + token
      config.headers["Authorization"] = token;
    }

    // var lang = localStorage.getItem('lang')
    // if (lang) {
    //   config.headers['language'] = lang
    // }
    return config;
  },
  (err) => {
    // console.log(err,'err');
    return Promise.reject(err);
  }
);

// http 响应拦截器
service.interceptors.response.use(
  (response) => {
    var res = response.data;
    if (res.code == 401) {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
      }
    }
    return res;
  },
  (error) => {
    // console.log(error,'error');
    return Promise.reject(error);
  }
);

export default service; // 这句千万不能漏下！！！

/**
 * post 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, params = {}) {
  var time = new Date();
  var IPSN = Math.round(Math.random()*100000000)
  url = url + `?times=${time}&IPSN=${IPSN}`
  return new Promise((resolve, reject) => {
    service.post(url, params).then(
      (response) => {
        if (response.code != 0) {
          // Notify({
          //   message: response.msg,
          //   duration: 2000,
          //   //background: '#1989fa'
          // });
          console.log(response.msg)
          Vue.prototype.$message.error(response.msg);
          if (
            response.code == 1000 ||
            response.code == 1001 ||
            response.code == 1002
          ) {
            //dengluguoqi
            localStorage.removeItem("token");
            // router.push("/login");
            router.push("/")
            setTimeout(() => {
              router.go(0)
            }, 1000);
           
          }
        }
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function post1(url, params = {}) {
  return new Promise((resolve, reject) => {
    service.post(url, undefined, { params }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function post2(url, params) { 
  var time = new Date();
  var IPSN = Math.round(Math.random()*100000000)
  url = url + `${params}&times=${time}&IPSN=${IPSN}`
  return new Promise((resolve, reject) => {
    service.post(url, params).then(
      (response) => {
        if (response.code != 0) {
        
          if(response.code != 30000){
           
            Vue.prototype.$message.error(response.msg);
          }
          if (
            response.code == 1000 ||
            response.code == 1001 ||
            response.code == 1002
          ) {
            //dengluguoqi
            if (localStorage.getItem("token")) {
              localStorage.removeItem("token");
            }
            // localStorage.removeItem("token");
            // localStorage.removeItem("downShow");
            // router.push("/login");
            router.push("/");
            // store.dispatch('setLoginShow', true);
          }
        }
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * get 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, data = {}) {
  data.newTime = new Date();
  data.IPSN = Math.round(Math.random()*100000000)
  return new Promise((resolve, reject) => {
    service.get(url, { params: data }).then(
      (response) => {
        if (response.code != 0) {
          // Notify({
          //   message: response.msg,
          //   duration: 2000,
          //   //background: '#1989fa'
          // });
          Vue.prototype.$message.error(response.msg);

          if (
            response.code == 1000 ||
            response.code == 1001 ||
            response.code == 1002
          ) {
            //dengluguoqi
            localStorage.removeItem("token");
            router.push("/")
            setTimeout(() => {
              router.go(0)
            }, 1000);
          }
        }

        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// DELETE
export function del(url, params = {}) {
  return new Promise((resolve, reject) => {
    service.delete(url, params).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// PUT
export function put(url, params = {}) {
  return new Promise((resolve, reject) => {
    service.put(url, params).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
/**
 * 其他delete等的封装类似
 * 可以查看中文文档 自行封装
 */
